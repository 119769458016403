<template>
  <div ref="main-dashborad" class="work-permit-display-body" @keydown.esc="fullscreenChange">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card cardcontents workPermitDisplaycardlayer">
          <div class="card-header non-label-icon mapheader workPermitDisplaycardheader topworkPermitDisplaylayer">
            <div class="title"><span class="title1">안전작업허가서 현황</span> <span class="title3">SWP</span></div>
            <q-btn 
              flat round 
              color="white" 
              class="workPermitDisplaymapstart fullscreen-btn"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'" 
              @click="clickFullScreen">
              <q-tooltip v-if="fullscreen">
                <!-- 원래대로 -->
                {{$label('LBL0000327')}}
              </q-tooltip>
              <q-tooltip v-else>
                <!-- 전체화면 -->
                {{$label('LBL0000328')}}
              </q-tooltip>
            </q-btn>
            <div class="card-more wokr-permit-display-header-input">
              <c-datepicker
                v-show="mapObj.activeTask===1"
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
            </div>
            <!-- 시작 -->
            <!-- 정지 -->
            <q-btn 
              :outline="!mapObj.interval.isPause" 
              :color="mapObj.interval.isPause ? 'green-6' : 'white'" 
              :text-color="mapObj.interval.isPause ? 'white' : 'white'" 
              size="11px" class="workPermitDisplaymapstart startstopbtn"
              :label="mapObj.interval.isPause ? $comm.getLangLabel('LBL0000338') : $comm.getLangLabel('LBL0000339')"
              :icon="mapObj.interval.isPause ? 'restart_alt' : 'pause'"
              @click="pause">
              <!-- 업무영역 Rotate -->
              <q-tooltip>작업허가 위치 Rotate</q-tooltip>
            </q-btn>
            <div v-if="mapObj.interval.isPause" class="card-more main-header-input mainmapstartSecond">
              <c-text
                label=""
                prefix="반복 시간"
                suffix="(초)"
                type="number"
                name="settingSeconds"
                v-model="settingSeconds">
              </c-text>
            </div>
          </div>
          <div class="card-body workPermitDisplaycardbody" ref="carousel" style="padding: 15px !important;" :loading="mapObj.loading">
            <template v-if="!mapObj.loading">
              <q-carousel
                v-if="mapObj.maps && mapObj.maps.length > 0"
                class="workPermit-carousel"
                swipeable
                animated
                arrows
                infinite
                control-type="regular"
                control-color="grey-6"
                v-model="mapObj.slide"
                @input="changeCarousel"
              >
                <q-carousel-slide 
                  v-for="(map, idx) in mapObj.maps"
                  :key="idx"
                  :name="idx" 
                  :img-src="map.mapSrc">
                  <template>
                    <div class="mapTitleDiv" v-text="map.mapName"></div>
                    <!-- 작업허가서 -->
                    <template v-if="mapObj.activeTask===1">
                      <VueDraggableResizable
                        v-for="(item, idx) in map.maps"
                        :key="idx"
                        ref="markImage"
                        class="workPermitDisplayMarkImage"
                        :class="item.idx === mapObj.maps[0].haveWorkPermitIdxs[mapObj.activeMarked - 1] ? 'selected' : ''"
                        :resizable="false"
                        :parent="true"
                        :draggable="false"
                        :x="item.locationXcoordinate * mapRate * mapObj.ratio"
                        :y="item.locationYcoordinate * mapRate * mapObj.ratio"
                        :w="40 " :h="40"
                        :grid="[20, 20]"
                      >
                        <c-map-marker :isSelected="item.idx === mapObj.maps[0].haveWorkPermitIdxs[mapObj.activeMarked - 1]">
                          <template v-slot:pinInner>
                            <div class="bg-white">
                              <q-icon name="font_download" class="text-primary workPermitTypeIcon" style="font-size: 32px;" />
                              <q-icon name="font_download" class="text-primary workPermitTypeIcon" style="font-size: 32px;" />
                            </div>
                          </template>
                        </c-map-marker>
                      </VueDraggableResizable>
                    </template>
                    <!-- 작업허가서를 제외한 나머지 업무 -->
                    <!-- <VueDraggableResizable
                      v-for="(location, idx) in map.otherMaps"
                      :key="'other' + idx"
                      :ref="'locationMark' + location.mdmLocationId"
                      class="workPermitDisplayMarkImage"
                      :class="location.idx === mapObj.maps[0].haveWorkPermitIdxs[mapObj.activeMarked - 1] ? 'selected' : ''"
                      style="z-index:1"
                      :parent="true"
                      :draggable="false"
                      :resizable="false"
                      :x="location.x * mapRate * mapObj.ratio"
                      :y="location.y * mapRate * mapObj.ratio"
                      :w="40 " :h="40"
                      :grid="[20, 20]"
                    >
                      <c-map-marker :isSelected="location.idx === mapObj.maps[0].haveWorkPermitIdxs[mapObj.activeMarked - 1]" />
                    </VueDraggableResizable> -->
                    <VueDraggableResizable
                      v-for="(area, idx) in map.areas"
                      :key="'area' + idx"
                      class="work-permit-display-area-class"
                      style="z-index:1"
                      :parent="true"
                      :draggable="false"
                      :resizable="false"
                      :x="area.x * mapRate * mapObj.ratio"
                      :y="area.y * mapRate * mapObj.ratio"
                      :w="area.w * mapRate * mapObj.ratio" 
                      :h="area.h * mapRate * mapObj.ratio"
                      :grid="[20, 20]"
                    >
                      <!-- :class="area.idx === mapObj.maps[0].haveWorkPermitIdxs[mapObj.activeMarked - 1] ? 'my-active-class' : ''" -->
                      <!-- @activated="onMarkedInfo(area)" -->
                      <!-- <div style="text-align:center;">{{area.areaName}}</div> -->
                      <q-menu 
                        :ref="'areaproxy' + area.idx" 
                        :persistent="true"
                        :content-class="'mainMapMenuLayer'"
                        anchor="top right"
                        self="top left">
                        <!-- <q-toolbar class="bg-primary text-white shadow-2">
                          <q-toolbar-title>작업허가서 ({{selectedWorkPermit.areaName}})</q-toolbar-title>
                        </q-toolbar> -->
                        <q-card 
                          v-for="(workPermit, idx) in selectedWorkPermit.workPermits"
                          :key="idx"
                          flat bordered 
                          :class="['work-permit-display-card', setStepClass(workPermit.swpStepCd)]">
                          <q-item class="work-permit-display-card-section work-permit-display-card-item">
                            <q-item-section avatar>
                              <q-badge rounded :color="setStepColor(workPermit.swpStepCd)" :label="workPermit.swpStepName" />
                            </q-item-section>

                            <q-item-section>
                              <q-item-label>
                                <q-icon 
                                  v-for="(icon, iconIdx) in setTypeIcons(workPermit.sopWorkTypeCds)"
                                  :key="iconIdx"
                                  :name="icon" 
                                  class="text-red"
                                  size="25px" />
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                          <q-card-section class="work-permit-display-card-section">
                            <q-icon 
                              name="chevron_right" 
                              class="text-indigo" 
                            />
                            <b>{{workPermit.workSummary}}</b> ({{workPermit.workTime}})
                          </q-card-section>
                          <q-card-section class="work-permit-display-card-section" v-if="workPermit.vendorName">
                            <q-icon 
                              name="chevron_right" 
                              class="text-indigo" 
                            /><b>작업업체 : {{workPermit.vendorName}}</b>
                          </q-card-section>
                          <q-card-section class="work-permit-display-card-section" v-if="workPermit.processName || workPermit.equipmentName">
                            <div v-if="workPermit.processName" class="main-work-permit-card-proc">
                              <q-chip
                                outline square
                                color="blue"
                                text-color="white">
                                {{workPermit.processName}}
                              </q-chip>
                            </div>
                            <div v-if="workPermit.equipmentName" class="main-work-permit-card-equip">
                              <q-chip
                                outline square
                                color="green"
                                text-color="white">
                                {{workPermit.equipmentName}}
                              </q-chip>
                            </div>
                          </q-card-section>
                        </q-card>
                      <!-- </q-popup-proxy> -->
                      </q-menu>
                    </VueDraggableResizable>
                  </template>
                </q-carousel-slide>
              </q-carousel>
              <!-- 등록된 지도가 없습니다. -->
              <el-empty v-else 
                :image-size="435"
                :description="$comm.getLangMessage('MSG0000122')">
              </el-empty>
            </template>
            <template v-else>
            </template>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <q-inner-loading
      :showing="mapObj.loading"
      label="화면 조정중입니다."
      label-class="text-blue-grey"
      label-style="font-size: 8em"
      size="12em"
    >
      <q-spinner-grid size="12em" color="blue-grey" />
      <div class="text-blue-grey" style="font-size: 3em">
        화면 조정중
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'swpMapDisplay',
  components: { 
    VueDraggableResizable,
  },
  props: {
  },
  data() {
    return {
      settingSeconds: 5,
      fullscreen: false,
      maintab1: 'dept',
      mapObj: {
        activeTask: 1,
        activeMarked: 1,
        envUrl: '',
        markInfoUrl: '',
        url: '',
        interval: {
          obj: null,
          seconds: 5,
          isPause: false,
          loopSeconds: 5,
        },
        taskParam: {
          workPermitPeriod: [],
        },
        location: {
          mdmLocationId: '',
          locationName: '',
          depts: [],
          chemprods: [],
          equips: [],
          hazardChems: [],
        },
        maps: [],
        orignMaps: [],
        slide: 0,
        height: 800,
        ratio: 1,
        orignRatio: 1,
        endIdx: 1,
        loading: false,
      },
      drawer: {
        visible: true,
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  activated() {
    this.intervalActionMap();
  },
  deactivated() {
    window.removeEventListener('resize', this.setSize);
    clearInterval(this.mapObj.interval.obj);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    mapRate() {
      return 1
    },
    isMapData() {
      return this.mapObj.maps
        && this.mapObj.maps.length > 0
        && this.$_.every(this.mapObj.maps, item => (item.maps && item.maps.length > 0) 
            // || (item.otherMaps && item.otherMaps.length > 0)
      )
    },
    selectedWorkPermit() {
      let selected = {
        areaName: '',
        direction: 'rtl',
        workPermits: [],
      }
      if (this.isMapData && this.mapObj.activeMarked > 0) {
        let item = this.mapObj.maps[this.mapObj.slide] // 지도 1개
        let area = this.$_.find(item.areas, { idx: this.mapObj.maps[this.mapObj.slide].haveWorkPermitIdxs[this.mapObj.activeMarked - 1] });
        if (area) {
          selected.areaName = area.areaName
          selected.direction = area.direction
          let maps = this.$_.map(this.$_.filter(item.maps, { idx: this.mapObj.maps[this.mapObj.slide].haveWorkPermitIdxs[this.mapObj.activeMarked - 1] }), 'resultTypes');
          if (maps && maps.length > 0) {
            this.$_.forEach(maps, map => {
              selected.workPermits = this.$_.concat(selected.workPermits, map)
            })
          }
          // let otherMaps = this.$_.map(this.$_.filter(item.otherMaps, { idx: this.mapObj.maps[0].haveWorkPermitIdxs[this.mapObj.activeMarked - 1] }), 'tasks');
          // if (otherMaps && otherMaps.length > 0) {
          //   this.$_.forEach(otherMaps, map => {
          //     selected.workPermits = this.$_.concat(selected.workPermits, map)
          //   })
          // }
        }
      }
      return selected;
    },
  },
  watch: {
  },
  methods: {
    init() {
      // url setting
      this.mapObj.url = selectConfig.sop.swp.workPermit.workMap.url
      this.mapObj.markInfoUrl = selectConfig.main.mapProcess.url
      this.mapObj.envUrl = selectConfig.main.env.url
      // list setting
      this.getMapPriodParam();
      this.getMapData();
      this.setSize(false);
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
      },);
    },
    getMapData(loading) {
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          /**
           * 지도는 1개
           */
          this.$_.forEach(_result.data, item => {
            let width = 800 * _result.data[0].ratio
            let widthHalf = width / 2

            /**
             * 총 20개의 구역을 마련 (4x5)
             */
            let heightDivide = 3
            let widthDivide = 4
            let area = {
              h: 800 / heightDivide,
              w: width / widthDivide,
            }
            item.areas = [];
            for (let height = 0; height < heightDivide; height++) {
              for (let width = 0; width < widthDivide; width++) {
                item.areas.push({
                  x: width * area.w,
                  y: height * area.h,
                  w: area.w,
                  h: area.h,
                  idx: (width + 1) + (height * widthDivide),
                  areaName: 'AREA ' + ((width + 1) + (height * widthDivide)),
                  direction: widthHalf > width * area.w ? 'rtl' : 'ltr'
                })
              }
            }

            // 작업허가서가 존재하는 idx 수집
            item.haveWorkPermitIdxs = [];

            // if (item.otherMaps && item.otherMaps.length > 0) {
            //   this.$_.forEach(item.otherMaps, map => {
            //     let area = this.$_.find(item.areas, area => {
            //       return area.x <= map.x + 20 && area.x + area.w > map.x + 20
            //         && area.y <= map.y + 20 && area.y + area.h > map.y + 20
            //     })
            //     this.$set(map, 'idx', area ? area.idx : 0)
            //     this.$set(map, 'direction', widthHalf > map.x ? 'rtl' : 'ltr')
            //     if (this.$_.indexOf(item.haveWorkPermitIdxs, map.idx) === -1 && map.tasks && map.tasks.length > 0) {
            //       item.haveWorkPermitIdxs.push(map.idx)
            //     }
            //   })
            // }
            if (item.maps && item.maps.length > 0) {
              this.$_.forEach(item.maps, map => {
                let area = this.$_.find(item.areas, area => {
                  return area.x <= map.locationXcoordinate + 20 && area.x + area.w > map.locationXcoordinate + 20
                    && area.y <= map.locationYcoordinate + 20 && area.y + area.h > map.locationYcoordinate + 20
                })
                this.$set(map, 'idx', area ? area.idx : 0)
                this.$set(map, 'direction', widthHalf > map.locationXcoordinate ? 'rtl' : 'ltr')
                if (this.$_.indexOf(item.haveWorkPermitIdxs, map.idx) === -1 && map.resultTypes && map.resultTypes.length > 0) {
                  item.haveWorkPermitIdxs.push(map.idx)
                }
              })
            }
            item.haveWorkPermitIdxs = this.$_.sortBy(item.haveWorkPermitIdxs)
            item.endIdx = item.haveWorkPermitIdxs.length; // heightDivide * widthDivide;
          })

          this.mapObj.maps = this.$_.cloneDeep(_result.data)
          this.mapObj.orignRatio = this.mapObj.maps[0].ratio;
          
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);

          // 로딩바 제거
          if (loading) {
            this.mapObj.loading = false;
            this.pause();
          } else if (this.mapObj.maps.length > 0) {
            setTimeout(() => {
              this.$refs['areaproxy' + this.mapObj.maps[0].haveWorkPermitIdxs[0]][0].show()
            }, 100);
            this.intervalActionMap();
          }
        }
      },);
    },
    intervalActionMap() {
      /**
       * 순서 : mark > location area > map
       */
      this.mapObj.interval.obj = setInterval(() => {
        this.mapObj.interval.seconds--
        
        if (this.mapObj.interval.seconds === 0) {
          this.$refs['areaproxy' + this.mapObj.maps[this.mapObj.slide].haveWorkPermitIdxs[this.mapObj.activeMarked - 1]][0].hide()
          if (this.mapObj.activeMarked === this.mapObj.maps[this.mapObj.slide].endIdx) {
            this.mapObj.activeMarked = 1
          } else {
            this.mapObj.activeMarked++;
          }
          this.$refs['areaproxy' + this.mapObj.maps[this.mapObj.slide].haveWorkPermitIdxs[this.mapObj.activeMarked - 1]][0].show()
          this.mapObj.interval.seconds = this.mapObj.interval.loopSeconds;
        }
      }, 1000);
    },
    changeCarousel(val) {
      if (this.mapObj.maps[val].haveWorkPermitIdxs && this.mapObj.maps[val].haveWorkPermitIdxs.length > 0) {
        this.$refs['areaproxy' + this.mapObj.maps[val].haveWorkPermitIdxs[0]][0].show()
        this.mapObj.activeMarked = 1
      }
    },
    setSize(flag) {
      /**
       * 지도의 기본 높이는 800
       * 윈도우 사이즈에 맞추어 높이가 변경될 시에 그에 맞는 ratio가 따로 필요
       */
      if (flag !== false) {
        this.mapObj.loading = true;
        this.pause();
      }
      this.$set(this.mapObj, 'maps', [])
      let margin = this.fullscreen === false ? 200 : 105;
      this.mapObj.height = window.innerHeight - margin
      let resizeRatio = Math.round(this.mapObj.height / 800 * 100) / 100;
      let _height = this.mapObj.height * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      this.mapObj.ratio = resizeRatio;
      setTimeout(() => {
        if (flag !== false) {
          this.getMapData(true);
        }
      }, 300);
    },
    pause() {
      this.mapObj.interval.isPause = !this.mapObj.interval.isPause
      if (this.mapObj.interval.isPause) {
        clearInterval(this.mapObj.interval.obj);
      } else {
        this.mapObj.interval.loopSeconds = this.settingSeconds;
        this.intervalActionMap()
      }
    },
    imgHeight(map) {
      return String(map.ratio * this.mapObj.height) + 'px'
    },
    handleClose() { },
    setStepColor(swpStepCd) {
      let color = '';
      switch(swpStepCd) {
        case 'SS00000005':
          color = 'light-blue';
          break;
        case 'SS00000010':
          color = 'amber';
          break;
        case 'SS00000020':
          color = 'light-green';
          break;
      }
      return color;
    },
    setStepClass(swpStepCd) {
      let _class = '';
      switch(swpStepCd) {
        case 'SS00000005':
        _class = 'main-work-permit-card-light-blue';
          break;
        case 'SS00000010':
        _class = 'main-work-permit-card-amber';
          break;
        case 'SS00000020':
        _class = 'main-work-permit-card-light-green';
          break;
      }
      return _class;
    },
    setTypeIcons(sopWorkTypeCds) {
      let icons = [];
      let typeArray = sopWorkTypeCds && sopWorkTypeCds.length > 0 ? sopWorkTypeCds.split(',') : [];
      if (typeArray && typeArray.length > 0) {
        this.$_.forEach(typeArray, type => {
          switch(type) {
            case 'SPT0000001': // 일반
              icons.push('engineering');
              break;
            case 'SPT0000005': // 화기
              icons.push('local_fire_department');
              break;
            case 'SSWT000001': // 밀폐공간
              icons.push('dangerous');
              break;
            case 'SSWT000005': // 정전
              icons.push('flash_off');
              break;
            case 'SSWT000010': // 방사선
              icons.push('warning');
              break;
            case 'SSWT000015': // 굴착
              icons.push('hardware');
              break;
            case 'SSWT000020': // 고소
              icons.push('stairs');
              break;
            case 'SSWT000025': // 중장비
              icons.push('agriculture');
              break;
          }
        })
      }
      return icons;
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    /* eslint-disable no-unused-vars */
    getWidth(item) {
      let width = 0;
      this.$_.forEach(item.resultTypes, resultType => {
        width += (resultType.sopWorkTypeName.length * 10) + 60
      })
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > this.mapObj.height) {
        left = this.mapObj.height * this.mapObj.ratio * this.mapRate - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 800
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > this.mapObj.height) {
        top = this.mapObj.height * this.mapObj.ratio * this.mapRate - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    },
    clickFullScreen() {
      let element = this.$refs['main-dashborad']
      if (this.fullscreen === false) {
        this.fullscreen = true;
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

        if (requestMethod) { // Native full screen.
          requestMethod.call(element);
        }
      } else {
        this.fullscreen = false;
        let requestMethod = document.exitFullscreen || document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || document.msExitFullscreen;
        if (requestMethod) { // Native full screen.
          requestMethod.call(document);
        }		
      }
    },
    onMarkedInfo(area) {
      this.$refs['area2'][0].$el.click()
    },
  }
};
</script>
<style lang="sass">
  .work-permit-display-body:fullscreen 
    overflow: scroll !important
  .work-permit-display-body:-ms-fullscreen 
    overflow: scroll !important
  .work-permit-display-body:-webkit-full-screen 
    overflow: scroll !important
  .work-permit-display-body:-moz-full-screen 
    overflow: scroll !important
  
  .mainlayoutback
    background-color: #161d31 !important
  .mainlayoutback .row > .col-sm-12:not(:last-child)
    padding-right: 0px !important
  .topworkPermitDisplaylayer
    border-radius: 10px 10px 10px 10px !important
    height: 76px
    background-color: #283046 !important
    display: block
    div.title
      // padding: 10px 0px 0px 30px
      font-weight: 600
      color: #7367f0
      font-size: 20px
      display: inline-block
    .title1
      color: #fff
      font-size: 22px
    .title2
      color: #fff
      font-size: 32px
    .title3
      color: #7367f0
      font-size: 25px
    .mainbtns
      float: right !important
      display: inline-block
    .mainbtnscreen
      margin-top: 15px
      padding-right: 10px
      float: right !important
      display: inline-block
    .today
      color: #fb8c00
      padding-top: 35px
      padding-left: 23px
      font-weight: 600
  .workPermitDisplaycardlayer
    background-color: #161d31 !important
  .workPermitDisplaycardheader
    border-radius: 10px 10px 0 0 !important
    background-color: #343d55 !important
    .customdatelabel
      margin-top: 0px !important
    .cdatetimepic .inputClass
      width: 220px !important
      max-width: 220px !important
      background-color: #343d55 !important
      color: #fff !important
      border-width: 0px !important
    .cdatetimepic .mx-input-wrapper
      padding: 0px 3px !important
      margin-bottom: 0px !important
      .mx-icon-clear
        color: #fff !important
      .mx-icon-calendar
        color: #fff !important
  .workPermitDisplaymapstart
    float: right
    margin-right: 20px
  .startstopbtn
    margin-top: 4px
  .mainmapstartSecond
    float: right
    margin-right: 10px
    input
      color: #fff !important
    .q-field__suffix
      color: #fff !important
  .fullscreen-btn
    .q-btn__wrapper
      min-width: 10px !important
      min-height: 10px !important
      // max-width: 10px !important
      // max-height: 10px !important
  .wokr-permit-display-header-input
    width: 220px !important
  .workPermitDisplaycardbody
    background-color: #283046
    border-radius: 0 0 10px 10px !important
    
  .mainchild
    margin-top: -75px !important
    padding: 20px 20px 5px 20px
  .maincard
    margin-right: 6px
    margin-bottom: 6px
  .maincard:hover
    margin-top: -10px
  .cardcontents
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
    .card-header:not(.non-label-icon):before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      height: 50px
      color: #1ab394
      background: #f8f8f8
      display: inline-block
      padding-top: 8px
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #1ab394
  .maincard .card-body
    background: #fff
    border-radius: 5px
    padding: 15px !important
    .fw-bold
      font-weight: 600
    .material-icons
      font-size: 3em
      color: #ddd
      cursor: pointer
  .maincard .card-body.border-color-blue
    border-left: 4px solid #0061f2
  .maincard .card-body.border-color-red
    border-left: 4px solid #d92550
  .maincard .card-body.border-color-yullow
    border-left: 4px solid #f7b924
  .maincard .card-body.border-color-green
    border-left: 4px solid #3ac47d

.workPermitDisplayMarkImage
  padding: 0px !important
  // text-align: center
  font-size: 20px
  height: 30px !important
  position: absolute
  padding: 16px
  // color: #C10015
  z-index: 3 !important
  // background: rgba(0,0,0,0.47)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      border-radius: 5px
      .markImage-tran
        border-radius: 5px
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        padding: 1px !important
        border-radius: 5px 0px 0px 5px
        i
          font-size: 20px
        div
          font-size: 12px !important
      .markImage-abbreviation-banner.last-banner
        border-right: 0
        border-radius: 0px 5px 5px 0px
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        border-radius: 5px
        .q-banner__avatar i
          font-size: 20px !important
        .q-banner__content
          font-size: 12px !important
  .markedBadage
    font-family: "Nanum Gothic", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
.markImage-abbreviation > div
  -webkit-animation: fadeInFromNone 0.1s ease-out
  -moz-animation: fadeInFromNone 0.1s ease-out
  -o-animation: fadeInFromNone 0.1s ease-out
  animation: fadeInFromNone 0.1s ease-out

.workPermitDisplayMarkImage.selected
  z-index: 3000 !important


// .mark-list-enter-active, .mark-list-leave-active
//   transition: all 0.1s
.mark-list-enter, .mark-list-leave-to
  opacity: 0
  transform: translateY(5px)

.caption-work
  font-size: 0.9em
  // font-style: italic
  white-space: normal
  color: #555
  margin-top: 4px

.caption-work2
  font-size: 0.9em
  // font-style: italic
  white-space: normal
  color: #fff
  padding: 10px

.workPermit-carousel
  border-radius: 10px
  height: var(--map-height) !important
  .q-carousel__slide
    width: calc(var(--map-ratio) * var(--map-height)) !important
    margin: 0 auto
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      // overflow: hidden !important

.wokr-permit-display-header-input
  // margin-top: -5px
  padding-right: 5px
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.maintabbody
  background-color: #283046
.padding-left10
  padding-left: 10px !important

@-webkit-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@-moz-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@-o-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1


.main-map-card 
  border-radius: 10px !important
  border-width: 0px !important
  background-color: #161d31 !important
  .q-tab--inactive
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
  .q-tab--active
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
    background-color: #fff !important
    color:  #161d31 !important
  .q-tab__indicator
    display: none !important
  .customCardbody
    margin: 0px !important
    .main-card-head-text
      font-weight: 600
      font-size: 0.9rem
      line-height: 26px
  .cardhead
    height: 41px !important
    background-color: #343d55 !important
    color: #fff !important
    padding-top: 5px !important
    font-size: 1.1em !important
  .customCardbody
    background-color: #283046 !important
    border-radius: 0 0 10px 10px !important
    padding-top: 10px !important
  .q-separator--horizontal
    display: none !important
  .mainDashboardTab
    .q-tab-panel
      display: table
      width: 100%
    .q-tab__label
      font-size: 1.0em !important
      font-weight: 600
  .mainDashboardTabS
    .q-tab__label
      font-size: 1.1em !important
  .maintabTable
    width: 100%
    display: inline-flex
    color: #fff !important
  .maintabTableTop
    border-top: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintabTd
    font-size: 1.1em !important
    padding: 8px 5px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
  .maintabTr
    font-size: 1.1em !important
    padding: 8px 5px
    width: 100%
    background-color: #161d31 !important
  .maintabTd7
    width: 70%
  .maintabTd6
    width: 60%
  .maintabTd5
    width: 50%
  .maintabTd4
    width: 40%
  .maintabTd3
    width: 30%
    text-align: right
  .maintabTd2
    width: 20%
  .maintabTd1
    width: 10%
  .maintabTdgreen
    color: #28c76f !important
    font-size: 1.05em !important
    text-align: center
  .maintablebody
    height: 250px
    background-color: #343d55 !important
    border-radius: 0 0 10px 10px !important
    overflow-y: auto
    border-bottom: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintablebody2
    height: 300px !important

.main-drawer.no-full-rtl
  top: 120px !important
  right: 30px !important
  bottom: 60px !important
.main-drawer.no-full-ltr
  top: 120px !important
  left: 270px !important
  bottom: 60px !important
.main-drawer.full-rtl
  top: 120px !important
  right: 30px !important
  bottom: 60px !important
.main-drawer.full-ltr
  top: 120px !important
  left: 30px !important
  bottom: 60px !important
.main-drawer
  .el-drawer
    border-radius: 10px 10px
.work-permit-display-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .work-permit-display-card-section
    padding: 0 10px 0 10px
  .work-permit-display-card-item
    min-height: auto

.v-modal
  z-index: 0 !important

.mainMapMenuLayer
  border-radius: 10px !important
  max-width: 600px !important
.main-work-permit-card-proc
  margin-right: 6px !important
.main-work-permit-card-equip, .main-work-permit-card-proc
  display: inline-block
  padding-top: 4px !important
  padding-bottom: 4px !important

.workPermitTypeIcon
  vertical-align: bottom
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.work-permit-display-area-class {
  /* background-color: #9E9E9E; */
  /* border: 1px solid rgba(255, 255, 255, 0.7);  */
  /* border: 1px solid black;
  background-color: rgba(40, 48, 70, 0.2);
  border-radius: 10px;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; */
  position: absolute;
  /* text-align: center; */
  color: black;
  /* cursor: pointer; */
  /* z-index: 1 !important; */
  border: none !important;
}
.work-permit-display-area-class div {
  width: 100%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  background-color: rgba(40, 48, 70, 0.6);
}
.my-active-class {
  /* opacity: 0.5; */
  background-color: rgba(115,103,240,0.5);
  /* color:azure; */
  z-index:3000;
}
.chip-task {
  margin-left: 8px !important;
}
.mainlayoutback .work-permit-display-body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.mainlayoutback .task-detail {
  z-index: 5;
  width:250px;
  position: relative;
}
.mainlayoutback .task-detail .q-banner {
  background-color: #283046;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0px !important;
}
.mainlayoutback .task-detail .q-banner .col-auto {
  padding: 0px !important;
}
.mainmapbannerTitle {
  width: 100%;
  color: #fff;
  background-color: #343d55;
  padding: 10px !important;
  border-radius: 3px;
}
.open-task {
  left: 100%; 
  width: 80%;
  transform: translate(-100%, 0);
  position: absolute;
  z-index: 1000;
  padding: 10px !important;
  border-radius: 15px !important;
  background: #fff;
  z-index: 2001;
}
.maintablebody::-webkit-scrollbar {
  width: 10px;
}
.maintablebody::-webkit-scrollbar-track {
  background: #343d55 !important;
  border-radius: 0 0 10px 0;
}

.maintablebody::-webkit-scrollbar-thumb {
  background-color: #161d31 !important;
  background-image: linear-gradient(315deg, #161d31 0%, #161d31 74%);
}
.link-line {
  text-decoration: underline;
  cursor: pointer;
}
.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
.main-work-permit-card-light-blue {
  border-top: 2px solid #03a9f4;
}
.main-work-permit-card-amber {
  border-top: 2px solid #ffc107;
}
.main-work-permit-card-light-green {
  border-top: 2px solid #8bc34a;
}

</style>